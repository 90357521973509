/* EatApp Restaurant Reservation Widget */
/* Need to add link like */
// <a
//     href="https://eatapp.co/reserve/roaring-rabbit-plot-no-pjcr39-east-crescent-road-palm-jumeirah-dubai-united-arab-emirates?source=iframe"
//     onClick="eatapp_ShowWidget.apply(this, arguments)"
// >
//     BOOK TABLE
// </a>

// Added disabled and enabled scrolling on open/close
// Also changed to global function
function eatapp_ShowWidget(event) {
    event.preventDefault();
    var url = event.target.href;
    var widget = document.createElement('div');
    widget.id = 'eatapp-widget';
    widget.onclick = eatapp_CloseWidget;
    tajexotica_disablescrolling();
    widget.innerHTML = '<style>#eatapp-widget{position:absolute;top:0;left:0;bottom:0;right:0;z-index:1000;background-color:rgba(0,0,0,.4);display:flex;align-items:center;justify-content:center}.eatapp-container{position:relative;width:100%;max-width:450px;height:80%;background-color:#fff;border-radius:5px;box-shadow:0 1px 3px rgba(0,0,0,.1);padding:2px}.eatapp-iframe-wrapper{border-radius:5px;box-sizing:border-box;width:100%;height:100%;overflow-x:hidden;overflow-y:auto;-webkit-overflow-scrolling:touch}.eatapp-iframe{display:block;height:100%;width:100%;border:none}.eatapp-close-button{position:absolute;top:-12px;right:-12px;width:24px;height:24px;float:right;cursor:pointer;background-color:#fff;border-radius:100px;border:1px solid rgba(0,0,0,.4);box-sizing:border-box}@media (max-width:479px){.eatapp-iframe-wrapper{margin:0;border-radius:0}.eatapp-container{height:100%;max-width:none;border-radius:0}.eatapp-iframe{border-radius:0;padding:0;box-shadow:none}.eatapp-close-button{position:absolute;top:10px;right:10px;width:24px;height:24px;float:right;cursor:pointer;background-color:transparent;box-shadow:none;border-radius:0;border:none}}</style><div class=eatapp-container><div class=eatapp-iframe-wrapper><iframe class=eatapp-iframe src="' + url + '"></iframe></div><img class=eatapp-close-button onclick=eatapp_CloseWidget.apply(this,arguments) src="https://d183cnjuwjcs99.cloudfront.net/assets/widget/widget-iframe-close-button.png"></div>';
    document.body.appendChild(widget)
}

function eatapp_CloseWidget(event) {
    event.stopPropagation();
    var widget = document.getElementById('eatapp-widget');
    tajexotica_enablescrolling();
    document.body.removeChild(widget)
}

/* Google Tag Manager */
/* The noscript is added in js_body_ultra_speed */


/* The MenuMundo is added on the specific pages with the outlet_id */

/* The noscript is added in js_body_ultra_speed */
<!-- Meta Pixel Code -->
!function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
        n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s)
}(window, document, 'script',
    'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '986200502033811');
fbq('track', 'PageView');
<!-- End Meta Pixel Code -->

setTimeout(function () {
    console.log('Run Chat with delay to let the rest of the assets load with priority')
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/629f2dda7b967b1179934a90/1g4usm89p';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        // s0.parentNode.insertBefore(s1, s0); // Can't move to a div, the script is adding the iframe outside
        // Canceled, not used
    })();
}, 3000) // Wait some time to add the Chat, to let prioritise the rest of the assets
